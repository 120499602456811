
h1, h2, h3, h4, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 15px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
}

.active {
  background-color: #A9A9A9;
}
